import React, { useState, useContext, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import theme from '../theme';

function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  return (
    <header style={{ fontWeight: '400', color: theme.colors.textheader, backgroundColor: theme.colors.header }}>
    <Link to="/">
      <div className="top-header" style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src="/imgs/logo_klaushof2.png" alt="Logo" className="center-logo" />
      </div>
    </Link>
    <div>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3 justify-content-center" light>
        <NavbarToggler style={{ border: 'none', boxShadow: 'none' }} onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse justify-content-center" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/events">Events</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/menu">Speisekarte</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/reservation">Reservierung</NavLink>
            </NavItem>
            {/*<NavItem>
              <NavLink tag={Link} style={{ color: theme.colors.textheader }}  to="/about">Galerie</NavLink>
            </NavItem>*/}
          </ul>
        </Collapse>
      </Navbar>  
    </div>
  </header>
  );
}

export default NavMenu;
