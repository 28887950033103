import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

export function Menu() {
  const [menu, setMenu] = useState(null);
  const [timeOfDay, setTimeOfDay] = useState('Abend'); // Default to "Abend"

  useEffect(() => {
    // Fetch menu data based on the selected timeOfDay
    fetch(`api/Menu/GetMenu?timeOfDay=${timeOfDay}`)
      .then(response => response.json())
      .then(data => setMenu(data))
      .catch(error => console.error('Error fetching menu:', error));
  }, [timeOfDay]); // Re-fetch data when timeOfDay changes

  const handleButtonClick = (selectedTimeOfDay) => {
    setTimeOfDay(selectedTimeOfDay);
  };

  return (
    <div style={{ display: 'flex', marginBottom: '60px', marginTop: '40px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
      <Helmet>
        <title>Speisekarte - Forsthaus Klaushof</title>
        <meta name="keywords" content="Forsthaus, Klaushof, Forsthaus Klaushof, Bad Kissingen, Kirchhof, Jan Kirchhof, Restaurant, Speisekarte, Menü, Essen, Karte" />
        <meta name="language" content="de" />
      </Helmet>
      {/*<h1 style={{ marginBottom: '40px' }}>Speisekarte</h1>*/}
      <div>
      {/*<div style={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between',alignItems: 'center', width: '100%', maxWidth: '700px' }} >
        <button
          className={`global-button ${timeOfDay !== 'Abend' ? 'deselected' : ''}`}
          onClick={() => handleButtonClick('Abend')}
        >
          Abendkarte
        </button>
        <button
          className={`global-button ${timeOfDay !== 'Mittag' ? 'deselected' : ''}`}
          onClick={() => handleButtonClick('Mittag')}
          style={{ marginRight: '10px' }}
        >
          Mittagskarte
        </button>
      </div>*/}
        {menu && menu.length === 0 ? (
          <p>Aktuell stellen wir unsere Speisekarte zusammen, daher müsst ihr euch noch etwas gedulden.</p>
          ) : (
            menu && menu.map((group, index) => (
            <div key={group.category} style={{ marginBottom: '50px' }}>
              {group.category && group.category.trim() !== '' && (
                <h2 style={{ marginBottom: '30px' }}>{group.category}</h2>
              )}
              {group.items.map(item => (
                <div key={item.name} style={{ marginBottom: '20px' }}>
                  <p><strong>{item.name}</strong> <br />
                  {item.description && item.description.trim() !== '' && (
                    <>
                      {item.description} <br />
                    </>
                  )}
                  {item.price && item.price.trim() !== '' && (
                    <>
                      {<em>{item.price}</em>} <br />
                    </>
                  )}
                  </p>
                </div>
              ))}
              {group.comment && group.comment.trim() !== '' && (
                <p style={{ marginTop: '50px' }}>
                  {group.comment.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
                </p>
              )}
              {index < menu.length - 1 && (
                <div style={{ marginTop: '40px'}}>
                  <hr style={{ width: '70%', margin: '20px auto', border: '0.75px solid #868686' }} />
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
