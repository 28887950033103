import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div style={{ marginBottom: '60px', marginTop: '40px', textAlign: 'center' }}>
        <Helmet>
          <title>Forsthaus Klaushof</title>
          <meta name="keywords" content="Forsthaus, Klaushof, Forsthaus Klaushof, Bad Kissingen, Wildpark, Restaurant, Jan Kirchhof, Kirchhof, Kiosk" />
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Willkommen im Forsthaus Klaushof</h1>
        <h3 style={{ marginBottom: '24px' }}>Unsere Öffnungszeiten</h3>
          <p><strong>Restaurant</strong></p>
          <p>Montag: Ruhetag<br/>
          Dienstag: 17:00 - 22:00 Uhr<br/>
          Mittwoch: Ruhetag<br/>
          Donnerstag: 11:30 - 22:00 Uhr<br/>
          Freitag: 11:00 - 22:00 Uhr<br/>
          Samstag: 11:00 - 22:00 Uhr<br/>
          Sonntag: 11:00 - 21:00 Uhr<br/>
          </p>
          <p><strong>Kiosk</strong></p>
          <p>Freitag 11:00 - 17:00 Uhr<br/>
          Samstag 11:00 - 17:00 Uhr<br/>
          Sonntag 11:00 - 17:00 Uhr<br/>
          <br/>
          </p>
          <h3 style={{ marginBottom: '25px' }}>Über das Forsthaus Klaushof</h3>
          <p><strong>Anfahrt</strong></p>
          <a href="https://www.google.com/maps/search/?api=1&query=Klaushofstraße+102,+97688+Bad+Kissingen" target="_blank" rel="noopener noreferrer">
            Klaushofstraße 102, 97688 Bad Kissingen
            <br/>
            <br/>
          </a>
          <p>Das Forsthaus Klaushof ist ein traditionsreiches Restaurant, idyllisch gelegen am Rande des Wildparks Klaushof in Bad Kissingen. Das historische Gebäude, einst als Forsthaus genutzt, bietet heute eine gemütliche Atmosphäre, in der sich Tradition und Moderne harmonisch verbinden. </p>
          <p><strong>Kulinarische Genüsse</strong></p>
          <p>Im Restaurant Forsthaus Klaushof erwartet Sie eine vielfältige Küche, die traditionelle Gerichte mit modernen Akzenten kombiniert. Besonders beliebt sind die Wildgerichte, die mit regionalen Zutaten frisch zubereitet werden. Lassen Sie sich von unseren saisonalen Spezialitäten verwöhnen und genießen Sie dazu eine Auswahl erlesener Weine. <br />
          <br />
          Der Kiosk bietet Wanderen, Radfahrern, Besuchern des Wildparks und sonstigen Besuchern eine Auswahl an Getränken und kleinen Speisen, sowie Kaffee und Kuchen.</p>
          <p><strong>Geschichte des Forsthauses</strong></p>
          <p>Das Forsthaus Klaushof blickt auf eine lange Geschichte zurück. Ursprünglich als Unterkunft für Forstarbeiter erbaut, hat es sich im Laufe der Jahre zu einem beliebten Ausflugsziel entwickelt. Die liebevoll restaurierten Räumlichkeiten bewahren den Charme vergangener Zeiten und laden Gäste dazu ein, in eine andere Epoche einzutauchen.</p>
          <p><strong>Umgebung und Aktivitäten</strong></p>
          <p>Die Umgebung des Forsthauses Klaushof bietet zahlreiche Freizeitmöglichkeiten. Der angrenzende Wildpark Klaushof ist ein Paradies für Tierliebhaber. Hier können Besucher heimische Wildtiere wie Hirsche, Wildschweine und Greifvögel in naturnaher Umgebung beobachten.<br />
          <br />
          Für Wanderfreunde gibt es gut ausgeschilderte Wanderwege, die durch die malerische Landschaft des Klauswaldes führen. Ob ein entspannter Spaziergang oder eine ausgedehnte Wanderung – hier findet jeder die passende Route.</p>
        </div>
    );
  }
}
