import React, { Component } from 'react';

export class Impressum extends Component {
  static displayName = Impressum.name;

  render() {
    return (
      <div style={{ marginBottom: '60px' }}>
        <h1 style={{ marginBottom: '40px' }}>Impressum</h1>
        <p style={{ marginBottom: '30px' }}>
          Jan Kirchhof <br />
          Ruhstrasse 6 <br />
          97688 Bad Kissingen <br />
          Germany
        </p>
        <p style={{ marginBottom: '30px' }}>
          E-Mail: info@forsthaus-klaushof.eu <br />
          Website: www.forsthaus-klaushof.eu
        </p>
        <p><strong>Rechtliche Hinweise</strong></p>
        <p style={{ marginBottom: '30px' }}>
        Der gesamte Inhalt der Website einschließlich aller Texte, Grafiken, Bilder, Fotos, Ton‑, Video- und Animationsdateien sind Eigentum der ho.li design GmbH. Alle Elemente der Website, einschließlich des allgemeinen Designs und des Inhalts, werden durch Urheberrechte geschützt. Die Vervielfältigung, Bearbeitung, Einspeicherung und jede Art der Verwertung des Inhalts, insbesondere der Texte und Bilder ist nicht gestattet bzw. bedürfen der schriftlichen Genehmigung des Erstellers.
        </p>
        <p><strong>Streitschlichtung</strong></p>
        <p style={{ marginBottom: '30px' }}>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:&nbsp;
          <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>. <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum. <br />
          <br />
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p><strong>Haftungshinweis</strong></p>
        <p style={{ marginBottom: '30px' }}>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
        <p><strong>Copyright</strong></p>
        <p>
          Alle auf dieser Website eingestellten Texte, Grafiken und Fotos sind urheberrechtlich geschützt und dürfen ohne schriftliche Genehmigung des Urhebers – privat oder kommerziell – weder kopiert, reproduziert und gedruckt, noch in anderer veränderter Art oder unveränderter Art genutzt werden.
        </p>
      </div>
    );
  }
}
