// theme.js

const theme = {
  colors: {
    header: '#E8E8E8',
    background: '#E8E8E8',
    footer: '#cfcfcf',
    button: '#cfcfcf',
    text: '#868686',
    textheader: '#868686',
    textfooter: '#666666'
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
    // add as many sizes as you need
  },
};

export default theme;