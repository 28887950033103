import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class About extends Component {
  static displayName = About.name;

  render() {
    return (
      <div style={{ marginBottom: '60px' }}>
        <Helmet>
          <title>Über uns - Forsthaus Klaushof</title>
          <meta name="language" content="de" />
        </Helmet>
        <h1 style={{ marginBottom: '40px' }}>Über uns</h1>
        <p>Herzlich Willkommen beim Forsthaus Klaushof! <br />
          ...
        </p>
      </div>
    );
  }
}
