import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class Events extends Component {
  static displayName = Events.name;

  render() {
    return (
      <div style={{ marginBottom: '60px', marginTop: '40px', textAlign: 'center' }}>
        <Helmet>
          <title>Events - Forsthaus Klaushof</title>
          <meta name="keywords" content="Forsthaus, Klaushof, Forsthaus Klaushof, Bad Kissingen, Jan Kirchhof, Kirchhof, Events, Veranstaltungen" />
          <meta name="language" content="de" />
        </Helmet>
        {/*<h1 style={{ marginBottom: '40px' }}>Events</h1>*/}
        <h2 style={{ marginBottom: '40px' }}>Neueröffnung am 2.11.2024</h2>
        <p>Am 2. November öffnet das Restaurant des Forsthof Klaushof in neuem Glanz unter neuer Belegschaft.</p>
      </div>
    );
  }
}
