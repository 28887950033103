import React, { Component } from 'react';
import theme from '../theme';

export class Footer extends Component {
  static displayName = Footer.name;

    render() {
        return (
            <footer style={{padding: '20px', backgroundColor: theme.colors.footer, color: theme.colors.textfooter }}>
                <div >
                    <a href="/impressum" style={{ fontSize: '18px', display: 'block', textDecoration: 'none', color: theme.colors.textfooter }}>Impressum</a>
                    <a href="/privacy" style={{ fontSize: '18px', display: 'block', textDecoration: 'none', color: theme.colors.textfooter }}>Datenschutz</a>
                </div>  
                <div style={{ fontSize: '14px', textAlign: 'right' }}>
                    &copy; 2024 Forsthaus Klaushof - Alle Rechte vorbehalten
                </div>
            </footer>
        );
    }
}
