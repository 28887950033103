//import { Shop } from "./components/Shop";
import { About } from "./components/About";
import { Home } from "./components/Home";
import { Impressum } from "./components/Impressum";
import { Privacy } from "./components/Privacy";
import { Reservation } from "./components/Reservation";
import { Menu } from "./components/Menu";
import { Events } from "./components/Events";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  /*{
    path: '/shop',
    element: <Shop />
  },*/
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/impressum',
    element: <Impressum />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/reservation',
    element: <Reservation />
  },
  {
    path: '/menu',
    element: <Menu />
  },
  {
    path: '/events',
    element: <Events />
  }
];

export default AppRoutes;
